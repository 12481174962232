body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custab{
  border: 1px solid #ccc;
  padding: 5px;
  margin: 5% 0;
  box-shadow: 3px 3px 2px #ccc;
  transition: 0.5s;
  }
.custab:hover{
  box-shadow: 3px 3px 0px transparent;
  transition: 0.5s;
  }

  .error-notice {
    margin: 5px 5px; /* Making sure to keep some distance from all side */
  }
  
  .oaerror {
    width: 90%; /* Configure it fit in your design  */
    margin: 0 auto; /* Centering Stuff */
    background-color: #FFFFFF; /* Default background */
    padding: 20px;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
    margin: 0 auto;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }
  
  .danger {
    border-left-color: #d9534f; /* Left side border color */
    background-color: rgba(217, 83, 79, 0.1); /* Same color as the left border with reduced alpha to 0.1 */
  }
  
  .danger strong {
    color:  #d9534f;
  }
  
  .warning {
    border-left-color: #f0ad4e;
    background-color: rgba(240, 173, 78, 0.1);
  }
  
  .warning strong {
    color: #f0ad4e;
  }
  
  .info {
    border-left-color: #5bc0de;
    background-color: rgba(91, 192, 222, 0.1);
  }
  
  .info strong {
    color: #5bc0de;
  }
  
  .success {
    border-left-color: #2b542c;
    background-color: rgba(43, 84, 44, 0.1);
  }
  
  .success strong {
    color: #2b542c;
  }